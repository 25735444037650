import { useMutation } from '@tanstack/react-query';

import { RunOrderImportParamsDTO } from '@app/models/orders';
import Api from '@app/services/api';

export const usePostOrderImportRun = () => {
  return useMutation({
    mutationFn: async (data: RunOrderImportParamsDTO) => {
      return await Api.orders.postImportsRun(data);
    },
    onError: (err) => {
      return err;
    },
    onSuccess: async (data) => {
      return data;
    },
  });
};
