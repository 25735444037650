import React, { FC } from 'react';

import { Button, Divider, Flex, Form, Layout, message, Row, Space, Spin, Steps, theme, Typography } from 'antd';
import type { UploadProps } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { getFormErrorsForAlert } from '@app/services/getFormErrorsFromResponse';
import { Upload } from '@components/AntFields';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';

import { usePostGetSettingsMapping, usePostImportsPreview } from '@pages/Catalogs/hooks';
import { usePostOrderImportRun } from '@pages/Catalogs/hooks/usePostOrderImportRun';

import { PreviewForm } from './components/PreviewForm';
import { Tables } from './components/Tables';
import { IFormValues, IRunFormValues } from './interfaces';
import { Container } from './styles';

const STEPS = [
  {
    title: 'Step 1',
    description: 'upload file',
  },
  {
    title: 'Step 2',
    description: 'config preview',
  },
  {
    title: 'Step 3',
    description: 'run',
  },
];

export const OrderImport: FC = () => {
  const params = useParams<{ id: string }>();
  const validationT = useTranslation(NSKeys.validation);
  const navigate = useNavigate();
  const [previewForm] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const [fileUid, setFileUid] = React.useState('');
  const commonT = useTranslation(NSKeys.common);
  const { mutate: getPreview, data: previewData, status: previewStatus, reset } = usePostImportsPreview();
  const { mutate: getSettings, data: settingsData, isLoading: settingsIsLoading } = usePostGetSettingsMapping();
  const { mutate: postRun, isLoading: runIsLoading, error } = usePostOrderImportRun();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [maxAvailableStep, setMaxAvailableStep] = React.useState(0);

  const goBack = () => navigate('/dashboard/catalogs');

  const onChange = (value: number) => {
    setCurrentStep(value);
  };

  React.useEffect(() => {
    if (error) {
      const parsedErrors = getFormErrorsForAlert(error, validationT.t);
      message.open({
        type: 'error',
        content: parsedErrors.length ? (
          <Flex>
            {parsedErrors.map((error) => (
              <Flex key={error.code} gap={8}>
                <Typography.Text>{error.codeMessage}: </Typography.Text>
                <Typography.Text>{error.fields.join(', ')}</Typography.Text>
              </Flex>
            ))}
          </Flex>
        ) : (
          commonT.t('error400')
        ),
      });
    }
  }, [error]);

  React.useEffect(() => {
    setMaxAvailableStep(fileUid ? 1 : 0);
    previewForm.setFieldValue('uploaded_file_uid', fileUid);
    reset();
  }, [fileUid]);

  React.useEffect(() => {
    if (params.id) {
      getSettings({ catalog_uid: params.id });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (previewData && previewStatus === 'success') {
      setCurrentStep(2);
      setMaxAvailableStep(2);
    }
  }, [previewData, previewStatus]);

  React.useEffect(() => {
    setCurrentStep(maxAvailableStep);
  }, [maxAvailableStep]);

  const onRemoveFile = () => setFileUid('');

  const handleChange: UploadProps['onChange'] = (info) => {
    const status = info.file.status;

    if (status === 'done' && info?.file?.response?.uploaded_file_uid) {
      setFileUid(info?.file?.response?.uploaded_file_uid);
    } else if (status === 'error') {
      message.error(commonT.t('error400'));
    }
  };

  const handlePreview = (formValues: IFormValues) => {
    getPreview(formValues);
  };

  const handleRun = async (values: IRunFormValues) => {
    const previewFormValues: IFormValues = previewForm.getFieldsValue();

    if (params.id) {
      postRun({
        uploaded_file_uid: previewFormValues.uploaded_file_uid,
        catalog_uid: params.id,
        layout: {
          direction: previewFormValues.layout.direction,
          orders_region: { ...previewFormValues.layout.orders_region, mapping: values.ordersMapping },
          items_region: { ...previewFormValues.layout.items_region, mapping: values.itemsMapping },
        },
      });
    }
  };

  const getDisplayValue = (index: number) => {
    return currentStep === index ? 'block' : 'none';
  };

  if (settingsIsLoading) {
    return (
      <Flex style={{ width: '100%', minHeight: '400px' }} justify="center" align="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title="order imports" onClick={goBack} />
          <Space size="middle">
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>

      <Container>
        <Flex vertical gap={16}>
          <Steps
            key={maxAvailableStep}
            current={currentStep}
            onChange={onChange}
            items={STEPS.map(({ title, description }, i) => ({
              title,
              description,
              disabled: i > maxAvailableStep,
            }))}
          />

          <div style={{ display: getDisplayValue(0) }}>
            <Upload
              apiPath="orders/order-imports/upload"
              listType="text"
              name="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              multiple={false}
              maxCount={1}
              description="DESCRIPTION WIP"
              onChange={handleChange}
              onRemove={onRemoveFile}
            />
          </div>

          <div style={{ display: getDisplayValue(1) }}>
            <PreviewForm form={previewForm} onFinish={handlePreview} />
          </div>

          <div style={{ display: getDisplayValue(2) }}>
            {previewData && (
              <Tables settingsData={settingsData} previewData={previewData} form={settingsForm} onFinish={handleRun} />
            )}
          </div>
        </Flex>
      </Container>
    </>
  );
};
