import React, { createContext, ReactNode, useContext, useState } from 'react';

interface IQueryParams {
  query?: string;
  sort?: string | string[];
}

const INITIAL_PARAMS = {
  query: '',
  sort: '',
};

interface IContext {
  queryParams: IQueryParams;
  setQueryParams: (data: IQueryParams) => void;
  resetQueryParams: () => void;
}

const ProcessDataContext = createContext<IContext>(null!);

function ProcessDataProvider({ children }: { children: ReactNode }) {
  const [queryParams, setQueryParams] = useState<IQueryParams>(INITIAL_PARAMS);

  const resetQueryParams = () => setQueryParams(INITIAL_PARAMS);

  const value = {
    queryParams,
    setQueryParams,
    resetQueryParams,
  };

  return <ProcessDataContext.Provider value={value}>{children}</ProcessDataContext.Provider>;
}

const useProcessDataParams = () => {
  const context = useContext(ProcessDataContext);

  if (context === undefined) {
    throw new Error('useRecipientContacts must be used within a RecipientContactsProvider');
  }

  return context;
};

export { ProcessDataContext, ProcessDataProvider, useProcessDataParams };
