import styled from 'styled-components';

import { Paper } from '@components/Paper';

export const Container = styled(Paper)`
  margin: 24px;
  padding: 24px;

  .ant-table-thead {
    display: none;
  }
`;
