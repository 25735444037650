import React, { FC } from 'react';

import { Flex, Form, Select, Table } from 'antd';

import { IOption } from '../../../../interfaces';

interface IProps {
  data: object;
  prefix: string;
  options: IOption[];
}

export const Summary: FC<IProps> = ({ data, options, prefix }) => {
  const keys = Object.keys(data);

  return (
    <>
      <Table.Summary.Row>
        {keys.map((el, index) => (
          <Table.Summary.Cell index={index} key={el}>
            <Flex vertical>
              <Form.Item name={[prefix, el]}>
                <Select style={{ width: 200 }} options={options} allowClear={true} />
              </Form.Item>
            </Flex>
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    </>
  );
};
