import React, { FC } from 'react';

import { Button, Divider, Flex, Form, FormInstance, Input, InputNumber, Select } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { LayoutDirection } from '@app/models/orders';

import { IFormValues } from '../../interfaces';

interface IProps {
  form: FormInstance;
  onFinish: (values: IFormValues) => void;
}

export const PreviewForm: FC<IProps> = ({ form, onFinish }) => {
  const validationT = useTranslation(NSKeys.validation);

  return (
    <Flex vertical gap={15}>
      <Form<IFormValues>
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          uploaded_file_uid: 'cu5nf1k11btg020v2jq0',
          layout: {
            direction: 'orders_horizontal',
            orders_region: { region_start: 'A3', region_end: 'J299' },
            items_region: { region_start: 'K1', region_end: 'S299' },
          },
        }}>
        <Form.Item<IFormValues> name="uploaded_file_uid" label="uploaded_file_uid" required style={{ display: 'none' }}>
          <Input disabled />
        </Form.Item>
        <Form.Item<IFormValues>
          name={['layout', 'direction']}
          label="direction"
          required
          rules={[
            {
              required: true,
              message: validationT.t('required'),
            },
          ]}>
          <Select
            options={[
              { value: LayoutDirection.OrdersHorizontal, label: 'orders_horizontal' },
              { value: LayoutDirection.OrdersVertical, label: 'orders_vertical' },
            ]}
          />
        </Form.Item>
        <Divider orientation="left" plain>
          orders_region
        </Divider>
        <Form.Item<IFormValues>
          name={['layout', 'orders_region', 'region_start']}
          label="region_start"
          required
          rules={[
            {
              required: true,
              message: validationT.t('required'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item<IFormValues> name={['layout', 'orders_region', 'region_end']} label="region_end">
          <Input />
        </Form.Item>
        <Form.Item<IFormValues> name={['layout', 'orders_region', 'size']} label="size">
          <InputNumber />
        </Form.Item>
        <Divider orientation="left" plain>
          items_region
        </Divider>
        <Form.Item<IFormValues>
          name={['layout', 'items_region', 'region_start']}
          label="region_start"
          required
          rules={[
            {
              required: true,
              message: validationT.t('required'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item<IFormValues> name={['layout', 'items_region', 'region_end']} label="region_end">
          <Input />
        </Form.Item>
        <Form.Item<IFormValues> name={['layout', 'items_region', 'size']} label="size">
          <InputNumber />
        </Form.Item>

        <Button type="primary" htmlType="submit" size="middle" loading={status === 'loading'}>
          PREVIEW
        </Button>
      </Form>
    </Flex>
  );
};
