import React from 'react';

import { Button, Flex, Form, message, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
// import { undefineToNull } from '@app/services/undefineToNull';
import { FieldGroupRender } from '@components/FieldGroupRender';
import { Paper } from '@components/Paper';

import { useCreateOrder, useGetProcessQuery } from '../../../../../../hooks';

interface IProps {
  cartUID: string;
  processUID: string;
}

export const OrderForm: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const nomenclatureCartT = useTranslation(NSKeys.nomenclatureCart);
  const commonT = useTranslation(NSKeys.common);

  const { mutateAsync, isLoading, status, data } = useCreateOrder();
  const { data: processData, status: loadingStatus } = useGetProcessQuery(props.processUID);

  React.useEffect(() => {
    if (status === 'success' && data) {
      message.success(nomenclatureCartT.t('orderForm.successMessage'));
      if (data.process?.uid) {
        navigate(`/dashboard/processes/${data.process?.uid}/orders/${data.uid}`);
      } else {
        navigate('/dashboard/processes');
      }
    }
    if (status === 'error') {
      message.error(commonT.t('error400'));
    }
  }, [status, data]);

  const handleSubmit = async (values: any): Promise<void> => {
    console.log(values);
    await mutateAsync({
      data: values,
      cart_uid: props.cartUID,
    });
  };

  return (
    <Paper padding="24px" style={{ margin: 24 }}>
      <Typography.Title level={5} style={{ margin: '0 0 24px 0' }}>
        {nomenclatureCartT.t('orderTitle')}
      </Typography.Title>

      <Form
        form={form}
        initialValues={{}}
        name="order_form"
        id="orderForm"
        onFinish={handleSubmit}
        autoComplete="off"
        labelCol={{ sm: 8 }}
        wrapperCol={{ sm: 8 }}>
        {loadingStatus === 'loading' && (
          <Flex justify="center">
            <Spin />
          </Flex>
        )}
        {processData && <FieldGroupRender form={form} items={processData.order_structure?.items || []} />}
        <Form.Item>
          <Button
            form="orderForm"
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            onClick={() => form.validateFields()}>
            {nomenclatureCartT.t('orderForm.submitButton')}
          </Button>
        </Form.Item>

        {/* <Form.Item noStyle shouldUpdate> */}
        {/*   {() => ( */}
        {/*     <Typography> */}
        {/*       <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre> */}
        {/*     </Typography> */}
        {/*   )} */}
        {/* </Form.Item> */}
      </Form>
    </Paper>
  );
};
