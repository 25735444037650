const getPrefix = (order: string): string => (order === 'ascend' ? '+' : '-');
const getKey = (el: any): string => (el.column?.fieldType === 'address' ? el.column?.key_for_filter : el.columnKey);

export const getSortValues = (data: any[] | any) => {
  if (Array.isArray(data)) {
    return data.reduce((acc, el) => [...acc, `${getPrefix(el.order)}${getKey(el)}`], []);
  } else if (typeof data === 'object' && data.order) {
    return [`${getPrefix(data.order)}${getKey(data)}`];
  }
  return [];
};
