import { MappingFieldDTO, MappingSettingsDTO } from '@app/models/orders';

import { IOption } from '../../../interfaces';
interface IResult {
  items: IOption[];
  orders: IOption[];
}

export const getOptions = (data?: MappingSettingsDTO): IResult => {
  let res: IResult = { orders: [], items: [] };

  const mapMapping = (mapping: MappingFieldDTO[]): IOption[] =>
    mapping.reduce((acc, { name, available_attributes }) => {
      const options = available_attributes.reduce(
        (acc, el) => [
          ...acc,
          { label: available_attributes.length > 1 ? `${name} (${el.name})` : el.name, value: el.attr },
        ],
        [] as IOption[]
      );
      // res = [...res, ...options];
      return [...acc, ...options];
    }, [] as IOption[]);

  if (data) {
    if (data.items_fields.length) {
      const items = mapMapping(data.items_fields);

      res = { items: items, orders: res.orders };
    }
    if (data.order_fields.length) {
      const orders = mapMapping(data.order_fields);

      res = { items: res.items, orders: orders };
    }
  }

  return res;
};
