export enum EOperations {
  // Равно (id==1)
  EQ = 'EQ',
  // Не равно (author.id!=10)
  NEQ = 'NEQ',
  // Меньше или равно
  LE = 'LE',
  // Меньше
  LT = 'LT',
  // Больше или равно
  GE = 'GE',
  // Больше
  GT = 'GT',
  // Одно из
  IN = 'IN',
  // Не входит во множество
  OUT = 'OUT',
  // Существует (не нулевое)
  EX = 'EX',
  // Пустое
  NEX = 'NEX',
  // содержит
  CONT = 'CONT',
}
