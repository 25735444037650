import React, { FC } from 'react';

import { FileExcelTwoTone, PlusOutlined, ShoppingTwoTone } from '@ant-design/icons';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { Badge, Button, Layout, message, Row, Space, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { RecordsListGenericSchemaViewProductInCatalogSchema, ViewProductInCatalogSchema } from '@app/models/products';
import { MATERIALS_LIST_TYPE } from '@app/services/appSettings';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { resetFilters } from '@app/store/productsReducer';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { DropDownMenuButton } from '@components/DropDownMenuButton';
import { HeaderTitle } from '@components/HeaderTitle';
import { InfiniteScroll } from '@components/InfiniteScroll';
import { CardComponent as Card, NotFound, SearchControls, SkeletonImage } from '@components/products';
import { CardsContainer } from '@pages/Products/pages/List/styles';

import { ProductDropDownMenu } from '../../components/ProductDropDownMenu';
import {
  useGetCart,
  useGetCatalogProductsQuery,
  useGetCatalogQuery,
  usePostCartRemoveItems,
  usePostUpdateCartItem,
} from '../../hooks';

import { Table } from './components';

export const Single: FC = () => {
  const queryClient = useQueryClient();

  const [dataSource, setDataSource] = React.useState<ViewProductInCatalogSchema[]>([]);
  const dispatch = useLocalDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const catalogsT = useTranslation(NSKeys.catalogs);
  const nomenclatureListT = useTranslation(NSKeys.nomenclatureList);
  const filterSearchString = useLocalSelector((state) => state.products.filterSearchString);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const filterTags = useLocalSelector((state) => state.products.filterTags);
  const filterCategories = useLocalSelector((state) => state.products.filterCategories);
  const { token } = theme.useToken();
  const [listType] = useLocalStorage(MATERIALS_LIST_TYPE, 'grid');

  const goBack = () => navigate('/dashboard/catalogs');

  const { data: cart } = useGetCart(id || '');
  const { mutateAsync: updateCart } = usePostUpdateCartItem();
  const { mutateAsync: removeItem } = usePostCartRemoveItems();
  const { data: catalog, isLoading: isCatalogLoading, isError } = useGetCatalogQuery(id || '');
  const {
    data: catalogProducts,
    hasNextPage,
    fetchNextPage,
    status,
  } = useGetCatalogProductsQuery({
    catalog_uid: id,
    limit: 10,
    category_id: filterCategories,
    tag_id: filterTags,
    q: filterSearchString,
  });

  React.useEffect(() => {
    if (isError) {
      goBack();
      message.error(catalogsT.t('errors.notFound'));
    }
  }, [isError]);

  React.useEffect(() => {
    setDataSource(catalogProducts?.pages?.map((data) => data.items || []).flat() || []);
  }, [catalogProducts]);

  const handleChangeCount = async (value: number, product: ViewProductInCatalogSchema) => {
    if (value === 0 && cart?.uid && product?.cart_item?.uid) {
      await removeItem({ cart_uid: cart.uid, catalog_uid: id || '', data: { item_uids: [product.cart_item.uid] } });

      queryClient.setQueryData<InfiniteData<RecordsListGenericSchemaViewProductInCatalogSchema>>(
        [
          'getCatalogProducts',
          {
            category_id: filterCategories,
            tag_id: filterTags,
            q: filterSearchString,
          },
        ],
        (data) =>
          data
            ? {
                ...data,
                pages: data.pages.map((el) => ({
                  ...el,
                  items: el.items.map((el) => ({
                    ...el,
                    cart_item: el.uid === product.uid ? null : el.cart_item ? { ...el.cart_item } : null,
                  })),
                })),
              }
            : data
      );
    }

    if (value > 0) {
      const updatedCart = await updateCart({
        catalog_uid: id || '',
        data: { product_uid: product.uid, quantity: value },
      });

      queryClient.setQueryData<InfiniteData<RecordsListGenericSchemaViewProductInCatalogSchema>>(
        [
          'getCatalogProducts',
          {
            category_id: filterCategories,
            tag_id: filterTags,
            q: filterSearchString,
          },
        ],
        (oldData) =>
          oldData
            ? {
                ...oldData,
                pages: oldData.pages.map((el) => ({
                  ...el,
                  items: el.items.map((el) => ({
                    ...el,
                    cart_item:
                      el.uid === updatedCart?.cart_item?.product?.uid
                        ? {
                            ...el.cart_item,
                            uid: updatedCart.cart_item.uid,
                            quantity: updatedCart.cart_item.quantity,
                          }
                        : el.cart_item
                        ? { ...el.cart_item }
                        : null,
                  })),
                })),
              }
            : oldData
      );
    }
  };

  return (
    <Layout>
      <Layout.Header>
        <Breadcrumbs apiTitles={[{ id: ':catalogId', value: catalog?.name }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle title={catalog?.name || id || ''} onClick={goBack} loading={isCatalogLoading}>
            <DropDownMenuButton
              type="primary"
              menu={[
                ...(catalog?.$permissions.update
                  ? [
                      {
                        label: <Typography.Text>{catalogsT.t('list.settings')}</Typography.Text>,
                        key: catalog?.uid || '',
                        onClick: () => navigate(`/dashboard/catalogs/edit/${catalog?.uid}`),
                      },
                    ]
                  : []),
              ]}
              placement="bottomRight"
            />
          </HeaderTitle>

          <Space size="middle">
            {!!currentSession?.productsContext?.scope?.$permissions?.create_products &&
              !!catalog?.$permissions?.manage_products && (
                <Button
                  onClick={() => navigate(`/dashboard/catalogs/${id}/add`)}
                  icon={<PlusOutlined />}
                  size="middle"
                  type="primary">
                  {nomenclatureListT.t('titleAdd')}
                </Button>
              )}
            <Button
              icon={<FileExcelTwoTone twoToneColor={token?.colorPrimary} />}
              size="middle"
              onClick={() => navigate(`/dashboard/catalogs/order-imports/${catalog?.uid}`)}
            />
            {catalog?.$permissions?.view_cart && (
              <Badge count={cart?.items_total} color={token.colorPrimary}>
                <Button
                  icon={<ShoppingTwoTone twoToneColor={token?.colorPrimary} />}
                  size="middle"
                  onClick={() => navigate('cart')}
                />
              </Badge>
            )}
          </Space>
        </Row>
        <SearchControls isLoading={status === 'loading'} />
      </Layout.Header>
      {listType === 'table' ? (
        <Table
          onCartCountChange={handleChangeCount}
          filterTags={filterTags}
          filterCategories={filterCategories}
          filterSearchString={filterSearchString}
          addToCardAvailable={catalog?.$permissions?.view_cart}
        />
      ) : (
        <Layout className="site-layout">
          <Layout.Content style={{ padding: '24px 0 24px 24px' }}>
            {catalog?.$permissions?.view_products ? (
              <CardsContainer>
                {dataSource.length === 0 && status === 'success' && !hasNextPage && (
                  <NotFound handleResetClick={() => dispatch(resetFilters())} />
                )}
                {!!dataSource.length &&
                  dataSource.map((product) => (
                    <Card
                      key={`${product.uid} ${product.cart_item?.uid}`}
                      product={product}
                      navigateToCardOnClick
                      onCartCountChange={(value) => handleChangeCount(value, product)}
                      addToCardAvailable={catalog?.$permissions?.view_cart}
                      extra={
                        <ProductDropDownMenu
                          catalog_uid={id || ''}
                          product_uid={product.uid}
                          manageable={catalog?.$permissions?.manage_products}
                        />
                      }
                    />
                  ))}
                {status === 'loading' && <SkeletonImage />}
                {hasNextPage && (
                  <InfiniteScroll key={catalogProducts?.pages?.length || 0} next={fetchNextPage}>
                    <SkeletonImage />
                  </InfiniteScroll>
                )}
              </CardsContainer>
            ) : (
              <CardsContainer>
                <Typography.Title level={5}>{catalogsT.t('noAccaessToProducts')}</Typography.Title>
              </CardsContainer>
            )}
          </Layout.Content>
        </Layout>
      )}
    </Layout>
  );
};
