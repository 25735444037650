/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddressForValidation */
export interface AddressForValidation {
  provider: AddressProvider;
  /** Provider Address Uid */
  provider_address_uid: string;
  /** Street Name */
  street_name: string;
  /** Street Number */
  street_number: string | null;
  /**
   * Place Name
   * Идентификатор помещения. Номер квартиры или номер офиса, например.
   */
  place_name: string | null;
}

/** AddressProvider */
export enum AddressProvider {
  Google = 'google',
  Mapbox = 'mapbox',
  Dadata = 'dadata',
}

/** CommonDictionariesSlugs */
export enum CommonDictionariesSlugs {
  ProductTags = 'product-tags',
  Retailers = 'retailers',
  RecipientTypes = 'recipient-types',
  RecipientTags = 'recipient-tags',
}

/** ContactUsage */
export interface ContactUsage {
  /** Orders */
  orders: SimpleOrder[];
}

/** ContextSchema */
export interface ContextSchema {
  scope: ContextScopeSchema;
}

/** ContextScopeSchema */
export interface ContextScopeSchema {
  /** ScopePermissions */
  $permissions: {
    /** View Dictionaries */
    view_dictionaries?: boolean;
    /** View Recipients */
    view_recipients?: boolean;
    /** View Contacts */
    view_contacts?: boolean;
  };
}

/** CreateContactSchema */
export interface CreateContactSchema {
  /** Name */
  name: string;
  /** Email */
  email: string | null;
  /** Phone */
  phone: string;
  /** Recipients */
  recipients: RecipientDTO[];
}

/** CreateDictionaryItemDTO */
export interface CreateDictionaryItemDTO {
  /** Title */
  title: string;
}

/** CreateOrLinkContactSchema */
export interface CreateOrLinkContactSchema {
  /** Uid */
  uid: string | null;
  /** Name */
  name?: string;
  /** Email */
  email?: string | null;
  /** Phone */
  phone?: string;
}

/** CreateRecipientSchema */
export interface CreateRecipientSchema {
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  /** Retailer Uid */
  retailer_uid: string | null;
  /** Type Uid */
  type_uid: string | null;
  /** Tags Uids */
  tags_uids: string[];
  address: AddressForValidation;
  /** Contacts */
  contacts: CreateOrLinkContactSchema[];
}

/** DictionaryItemNotUniqueError */
export interface DictionaryItemNotUniqueError {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'dictionary_title_not_unique';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  /** Meta */
  meta?: object | null;
}

/** DictionaryItemSchema */
export interface DictionaryItemSchema {
  /** Uid */
  uid: string;
  /** Title */
  title: string;
  /** DictionaryItemPermissions */
  $permissions: {
    /** Delete */
    delete?: boolean;
  };
}

/** DictionarySchema */
export interface DictionarySchema {
  /** Uid */
  uid: string;
  /** Slug */
  slug: string;
  /** Title */
  title: string;
  /** DictionaryPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Create Item */
    create_item?: boolean;
  };
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** ItemSchema */
export interface ItemSchema {
  /** Pk */
  pk: number;
  /** Value */
  value: string;
  /** Label */
  label: string;
  /** Title */
  title: string;
}

/** PartialUpdateContactSchema */
export interface PartialUpdateContactSchema {
  /** Name */
  name?: string;
  /** Email */
  email?: string | null;
  /** Phone */
  phone?: string;
  /** Recipients */
  recipients?: RecipientDTO[];
}

/** PartialUpdateRecipientSchema */
export interface PartialUpdateRecipientSchema {
  /** Name */
  name?: string;
  /** Code */
  code?: string | null;
  /** Retailer Uid */
  retailer_uid?: string | null;
  /** Type Uid */
  type_uid?: string | null;
  /** Tags Uids */
  tags_uids?: string[];
  /** Address */
  address?: AddressForValidation;
  /** Contacts */
  contacts?: CreateOrLinkContactSchema[];
}

/** RecipientCodeNotUniqueError */
export interface RecipientCodeNotUniqueError {
  /** Error Id */
  error_id: string;
  /** Title */
  title?: string | null;
  /** Detail */
  detail?: string | null;
  /** Code */
  code?: 'recipient_code_not_unique';
  /** Status Code */
  status_code?: number | null;
  source?: Source | null;
  /** Meta */
  meta?: object | null;
}

/** RecipientDTO */
export interface RecipientDTO {
  /** Uid */
  uid: string;
}

/** RecipientUsage */
export interface RecipientUsage {
  /** Orders */
  orders: SimpleOrder[];
}

/** RecordsListGenericSchema[ItemSchema] */
export interface RecordsListGenericSchemaItemSchema {
  /** Items */
  items: ItemSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveContactsSchema] */
export interface RecordsListGenericSchemaRetrieveContactsSchema {
  /** Items */
  items: RetrieveContactsSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveRecipientsSchema] */
export interface RecordsListGenericSchemaRetrieveRecipientsSchema {
  /** Items */
  items: RetrieveRecipientsSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RequestDumpItemSchema */
export interface RequestDumpItemSchema {
  /** Dictionary */
  dictionary: string;
  /** Value */
  value: string;
}

/** RetrieveAddressSchema */
export interface RetrieveAddressSchema {
  /** Uid */
  uid: string;
  provider: AddressProvider;
  /** Provider Address Uid */
  provider_address_uid: string;
  /** As String */
  as_string: string;
  /**
   * Country Iso Code
   * В Alpha-2 формате
   */
  country_iso_code: string;
  /** Country Name */
  country_name: string;
  /** Postal Code */
  postal_code: string;
  /** Region Name */
  region_name: string;
  /** City Name */
  city_name: string;
  /** Street Name */
  street_name: string;
  /** Street Number */
  street_number: string | null;
  /** Place Name */
  place_name: string | null;
  /** Lat */
  lat: number | null;
  /** Lon */
  lon: number | null;
}

/** RetrieveContactInRecipientSchema */
export interface RetrieveContactInRecipientSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Phone */
  phone: string;
  /** Email */
  email: string | null;
  /** ContactPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** RetrieveContactSchema */
export interface RetrieveContactSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Email */
  email: string | null;
  /** Phone */
  phone: string;
  /** Recipients */
  recipients: RetrieveRecipientInContactSchema[];
  /** ContactPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** RetrieveContactsSchema */
export interface RetrieveContactsSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Email */
  email: string | null;
  /** Phone */
  phone: string;
  /** ContactPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** RetrieveRecipientInContactSchema */
export interface RetrieveRecipientInContactSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  /** RecipientPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** RetrieveRecipientSchema */
export interface RetrieveRecipientSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  retailer: SimpleTagItemSchema | null;
  type: SimpleTagItemSchema | null;
  /** Tags */
  tags: SimpleTagItemSchema[];
  /** Contacts */
  contacts: RetrieveContactInRecipientSchema[];
  address: RetrieveAddressSchema;
  /** RecipientPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** RetrieveRecipientsSchema */
export interface RetrieveRecipientsSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  address: RetrieveSimpleAddressSchema;
  retailer: SimpleTagItemSchema | null;
  type: SimpleTagItemSchema | null;
  /** RecipientPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Manage */
    manage?: boolean;
  };
}

/** RetrieveSimpleAddressSchema */
export interface RetrieveSimpleAddressSchema {
  /** As String */
  as_string: string;
}

/** SimpleOrder */
export interface SimpleOrder {
  /** Uid */
  uid: string;
  /** Num */
  num: string;
}

/** SimpleTagItemSchema */
export interface SimpleTagItemSchema {
  /** Uid */
  uid: string;
  /** Title */
  title: string;
}

/** SystemSlugs */
export enum SystemSlugs {
  Currencies = 'currencies',
  Timezones = 'timezones',
  JobTitles = 'job_titles',
  Industries = 'industries',
  DimensionUnits = 'dimension_units',
  MassUnits = 'mass_units',
  Langs = 'langs',
  EmployeesCount = 'employees_count',
}

/** SystemValueSort */
export enum SystemValueSort {
  Pk = 'pk',
  Value = 'value',
}

/** UpdateContactSchema */
export interface UpdateContactSchema {
  /** Name */
  name: string;
  /** Email */
  email: string | null;
  /** Phone */
  phone: string;
  /** Recipients */
  recipients: RecipientDTO[];
}

/** UpdateRecipientSchema */
export interface UpdateRecipientSchema {
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  /** Retailer Uid */
  retailer_uid: string | null;
  /** Type Uid */
  type_uid: string | null;
  /** Tags Uids */
  tags_uids: string[];
  address: AddressForValidation;
  /** Contacts */
  contacts: CreateOrLinkContactSchema[];
}

/** UserLanguage */
export enum UserLanguage {
  EN = 'EN',
  RU = 'RU',
  PT = 'PT',
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** _Source */
export interface Source {
  /** Pointer */
  pointer?: string | null;
  /** Header */
  header?: string | null;
  /** Parameter */
  parameter?: string | null;
}
