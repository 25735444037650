import React from 'react';

import { Button, Flex, Form, Popover, Row, Skeleton, theme } from 'antd';
import { useDebounceCallback } from 'usehooks-ts';

import { EOperations, mapToValue, PopoverField, SearchBlock } from './components';
import { FormValues, IField } from './interfaces';
import { SelectItem } from './styles';

interface IProps {
  fields?: IField[];
  showSearchField?: boolean;
  loading?: boolean;
  requiredSearchField?: boolean;
  onSubmit?: (query: string) => void;
  initialValues?: Partial<FormValues>;
  placeholderSearch?: string;
}

export const FilterBlock = React.memo(function Greeting(props: IProps) {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [open, setOpen] = React.useState(false);
  const [newFilter, setNewFilter] = React.useState('');
  const [selectedFields, setSelectedFields] = React.useState<IField[]>([]);

  React.useEffect(() => {
    if (!!props?.fields?.length && props?.initialValues) {
      const initialFields: IField[] = [];
      for (const key in props.initialValues) {
        const filteredField = props.fields.find((field) => field?.name === key);
        if (filteredField && props.initialValues[key] !== undefined) {
          initialFields.push(filteredField);
        }
      }
      setSelectedFields([...initialFields]);
    }
  }, []);

  React.useEffect(() => {
    if (props?.initialValues) {
      form.setFieldsValue(props?.initialValues);
    }
  }, [props?.initialValues]);

  const handleSubmit = (values: FormValues) => {
    if (props.onSubmit) {
      const res = Object.keys(values).reduce((acc, el) => {
        let value = values[el]?.value;

        if (Array.isArray(value)) {
          if (value?.length === 1) {
            value = `${value[0].value}`;
          } else if (value?.length > 1) {
            value = `(${value.map((el: any) => `${el.value}`).join(',')})`;
          } else if (value?.length === 0) {
            value = undefined;
          }
        }

        if (values[el]?.operation === EOperations.CONT) {
          const res = `${el}=contains="${value}"`;
          return [...acc, res];
        }

        if (values[el]?.operation === EOperations.EX) {
          const res = el + '=ex=true';
          return [...acc, res];
        }

        if (values[el]?.operation === EOperations.NEX) {
          const res = el + '=ex=false';
          return [...acc, res];
        }

        if (value) {
          const res = el + mapToValue(values[el]?.operation) + `"${value}"`;
          return [...acc, res];
        }
        return acc;
      }, [] as string[]);

      props.onSubmit(res.join(';'));
    }
  };

  const handleSelectChange = (field: IField) => {
    setOpen(false);
    setNewFilter(field.name);
    setSelectedFields([...selectedFields, field]);
  };

  const handleRemoveField = (cField: IField) => {
    setOpen(false);
    setNewFilter('');
    setSelectedFields(selectedFields.filter((field) => field.name !== cField.name));
    form.setFieldValue(cField.name, undefined);
    form.submit();
  };

  if (props.loading) {
    return <Skeleton.Input style={{ width: '100%', marginBottom: '20px' }} active size="large" />;
  }

  const debouncedChange = useDebounceCallback(handleSubmit, 300);

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      name="filters_form"
      onValuesChange={(_, values) => debouncedChange(values)}
      initialValues={props.initialValues}>
      {props.showSearchField && <SearchBlock form={form} placeholderSearch={props.placeholderSearch} />}

      {props.fields && props?.fields?.length > 0 && (
        <Row style={{ gap: 12 }}>
          {props.fields
            .filter((object1) => selectedFields.some((object2) => object1.name === object2.name))
            .map((field, i) => (
              <PopoverField
                key={field.name}
                form={form}
                newFilter={newFilter === field.name}
                onRemove={() => handleRemoveField(field)}
                field={field}
              />
            ))}

          <Popover
            placement="bottomLeft"
            arrow={false}
            content={
              <Flex vertical justify="start">
                {props.fields
                  .filter((object1) => !selectedFields.some((object2) => object1.name === object2.name))
                  .map((field, i) => (
                    <SelectItem
                      key={field.name}
                      type="text"
                      icon={field.icon}
                      onClick={() => handleSelectChange(field)}>
                      {field.label}
                    </SelectItem>
                  ))}
              </Flex>
            }
            trigger="click"
            open={props.fields.length !== selectedFields.length && open}
            onOpenChange={setOpen}>
            <Button
              icon={<div>+</div>}
              disabled={props.fields.length === selectedFields.length}
              shape="round"
              type="text">
              <span style={{ color: token.colorTextDescription }}>Условие поиска</span>
            </Button>
          </Popover>
        </Row>
      )}
    </Form>
  );
});
