import { ColumnsType } from 'antd/es/table';

export const getColumns = (data?: object[]): ColumnsType<object> => {
  return data?.length
    ? Object.keys(data[0])?.map((el) => ({
        title: el,
        dataIndex: el,
        key: el,
      }))
    : [];
};
