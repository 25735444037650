/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Action */
export enum Action {
  Create = 'create',
  View = 'view',
  Manage = 'manage',
  Delete = 'delete',
  Export = 'export',
}

/** ActionConfig */
export interface ActionConfig {
  action: Action;
  /** Levels */
  levels: Level[];
  /** Restrictions */
  restrictions?: Restriction[];
}

/** AddressProvider */
export type AddressProvider = 'google';

/** ApplyResetSchema */
export interface ApplyResetSchema {
  /**
   * Password
   * @minLength 8
   */
  password: string;
  /** Repeated Password */
  repeated_password: string;
}

/** Body_uploadScopeFavicon */
export interface BodyUploadScopeFavicon {
  /**
   * Favicon
   * Image file
   * @format binary
   */
  favicon: File;
}

/** Body_uploadScopeLogo */
export interface BodyUploadScopeLogo {
  /**
   * Logo
   * Image file
   * @format binary
   */
  logo: File;
}

/** Body_uploadUserAvatar */
export interface BodyUploadUserAvatar {
  /**
   * Media
   * Image file
   * @format binary
   */
  media: File;
}

/** ChainLinkDTO */
export interface ChainLinkDTO {
  user_group: UserGroupDTO;
  /** Children */
  children?: ChainLinkDTO[];
}

/** ChainLinkSchema */
export interface ChainLinkSchema {
  user_group: UserGroupSchema;
  /** Children */
  children?: ChainLinkSchema[];
}

/** CloneOrderApprovalChainSchema */
export interface CloneOrderApprovalChainSchema {
  /** Name */
  name: string;
}

/** ConfirmationAlreadySentError */
export interface ConfirmationAlreadySentError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'confirmation_already_sent';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** ConfirmationExpiredError */
export interface ConfirmationExpiredError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'confirmation_expired';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** CreateInvitationDTO */
export interface CreateInvitationDTO {
  /** Email */
  email: string;
}

/** CreateInvitationError */
export enum CreateInvitationError {
  UserAlreadyExisting = 'user-already-existing',
  InvitationAlreadyExisting = 'invitation-already-existing',
  EmailSendingError = 'email-sending-error',
  Unknown = 'unknown',
}

/** CreateOrderApprovalChainSchema */
export interface CreateOrderApprovalChainSchema {
  /** Name */
  name: string;
  head: ChainLinkDTO;
}

/** CreateResetSchema */
export interface CreateResetSchema {
  /**
   * Username
   * @format email
   */
  username: string;
}

/** CreateRoleSchema */
export interface CreateRoleSchema {
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /** Permissions */
  permissions: PermissionInput[];
}

/** CreateUserGroupSchema */
export interface CreateUserGroupSchema {
  /** Name */
  name: string;
}

/** EmailOccupancySchema */
export interface EmailOccupancySchema {
  /**
   * Is Occupied
   * Занят ли этот email
   */
  is_occupied: boolean;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** ImageVariant */
export interface ImageVariant {
  /** Url */
  url?: string;
}

/** InvalidImageFileError */
export interface InvalidImageFileError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'invalid_image_file';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** InvalidMediaContentError */
export interface InvalidMediaContentError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'invalid_media_content';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** InvitationDTO */
export interface InvitationDTO {
  /** Uuid */
  uuid?: string | null;
  /** Email */
  email: string;
  /** Expired At */
  expired_at?: string | null;
  error?: CreateInvitationError | null;
}

/** InvitationExpiredError */
export interface InvitationExpiredError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'invitation_expired';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** LangsEnum */
export enum LangsEnum {
  RU = 'RU',
  EN = 'EN',
  PT = 'PT',
}

/** Level */
export enum Level {
  All = 'all',
  Allowed = 'allowed',
  Responsible = 'responsible',
  Group = 'group',
  Denied = 'denied',
}

/** ListRolesSchema */
export interface ListRolesSchema {
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /** Permissions */
  permissions: PermissionOutput[];
  /** Pk */
  pk: number;
  /** System */
  system: boolean;
  /** RolePermissions */
  $permissions: {
    /** Update */
    update?: boolean;
    /** Delete */
    delete?: boolean;
  };
}

/** ManageUsersDTO */
export interface ManageUsersDTO {
  /** Id */
  id: number;
}

/** ManageUsersUserGroupDTO */
export interface ManageUsersUserGroupDTO {
  /** Users */
  users: ManageUsersDTO[];
}

/** NotificationChannels */
export interface NotificationChannels {
  /**
   * Email
   * @default true
   */
  email?: boolean;
}

/** OrderApprovalChainInUseError */
export interface OrderApprovalChainInUseError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_approval_chain_name_in_use';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** OrderApprovalChainNameNotUnique */
export interface OrderApprovalChainNameNotUnique {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_approval_chain_name_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** OrderApprovalChainUsageDTO */
export interface OrderApprovalChainUsageDTO {
  /** Catalogs */
  catalogs: SimpleCatalogDTO[];
  /** Orders */
  orders: SimpleOrderDTO[];
}

/** Permission */
export interface PermissionInput {
  permission_scope: PermissionScope;
  /** Rules */
  rules: PermissionRule[];
}

/** Permission */
export interface PermissionOutput {
  permission_scope: PermissionScope;
  /** Rules */
  rules: PermissionRule[];
}

/** PermissionRule */
export interface PermissionRule {
  action: Action;
  /** @default "denied" */
  level?: Level;
}

/** PermissionScope */
export enum PermissionScope {
  Users = 'users',
  Products = 'products',
  Catalogs = 'catalogs',
  Orders = 'orders',
  Roles = 'roles',
  UserGroups = 'user_groups',
  OrderApprovalChains = 'order_approval_chains',
  RecipientsAndContacts = 'recipients_and_contacts',
  Processes = 'processes',
}

/** RecordsListGenericSchema[ListRolesSchema] */
export interface RecordsListGenericSchemaListRolesSchema {
  /** Items */
  items: ListRolesSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveGroupUserSchema] */
export interface RecordsListGenericSchemaRetrieveGroupUserSchema {
  /** Items */
  items: RetrieveGroupUserSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveOrderApprovalChainSchema] */
export interface RecordsListGenericSchemaRetrieveOrderApprovalChainSchema {
  /** Items */
  items: RetrieveOrderApprovalChainSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveUserGroupSchema] */
export interface RecordsListGenericSchemaRetrieveUserGroupSchema {
  /** Items */
  items: RetrieveUserGroupSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[RetrieveUserSchema] */
export interface RecordsListGenericSchemaRetrieveUserSchema {
  /** Items */
  items: RetrieveUserSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RefreshTokenSchema */
export interface RefreshTokenSchema {
  /** Refresh Token */
  refresh_token: string;
}

/** RegisterInvitedUserSchema */
export interface RegisterInvitedUserSchema {
  /** Invitation Uuid */
  invitation_uuid: string;
  /**
   * Password
   * Строка длиной более 8-ми символов.
   */
  password: string;
  /**
   * First Name
   * @minLength 1
   */
  first_name: string;
  /**
   * Last Name
   * @minLength 1
   */
  last_name: string;
  /**
   * Phone
   * @minLength 1
   */
  phone: string;
  /**
   * Title
   * @minLength 1
   */
  title: string;
}

/** RegisterUserSchema */
export interface RegisterUserSchema {
  /**
   * Username
   * @format email
   */
  username: string;
  /**
   * Password
   * Строка длиной более 8-ми символов.
   */
  password: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Title */
  title: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /** @default {"lang":"EN"} */
  settings?: SelfUserSettings;
}

/** Restriction */
export interface Restriction {
  /** Levels */
  levels: Level[];
  /** Rules */
  rules: RestrictionRule[];
}

/** RestrictionRule */
export interface RestrictionRule {
  scope: PermissionScope;
  action: Action;
  /** Levels */
  levels: Level[];
}

/** RetrieveGroupUserSchema */
export interface RetrieveGroupUserSchema {
  /** Id */
  id: number;
  /** Name */
  name: string;
  avatar: UserAvatarSchema | null;
}

/** RetrieveOrderApprovalChainSchema */
export interface RetrieveOrderApprovalChainSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  head: ChainLinkSchema | null;
  /** OrderApprovalChainPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Update */
    update?: boolean;
    /** Delete */
    delete?: boolean;
  };
}

/** RetrieveResetSchema */
export interface RetrieveResetSchema {
  /**
   * Expired At
   * @format date-time
   */
  expired_at: string;
}

/** RetrieveRoleSchema */
export interface RetrieveRoleSchema {
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /** Permissions */
  permissions: PermissionOutput[];
  /** Pk */
  pk: number;
  /** Code */
  code: string;
  /** Scope Pk */
  scope_pk: number;
  /** System */
  system: boolean;
  /** RolePermissions */
  $permissions: {
    /** Update */
    update?: boolean;
    /** Delete */
    delete?: boolean;
  };
}

/** RetrieveScopeSchema */
export interface RetrieveScopeSchema {
  /** Pk */
  pk: number;
  /** Title */
  title: string;
  settings: ScopeSettingsOutput;
  company: ScopeCompany;
  logo: ScopeLogoSchema | null;
  favicon: ScopeFaviconSchema | null;
  /** ScopePermissions */
  $permissions: {
    /** Update */
    update?: boolean;
    /** View */
    view?: boolean;
    /** Invite Users */
    invite_users?: boolean;
    /** View Roles */
    view_roles?: boolean;
    /** Create Roles */
    create_roles?: boolean;
    /** View Users */
    view_users?: boolean;
    /** View User Groups */
    view_user_groups?: boolean;
    /** Create User Groups */
    create_user_groups?: boolean;
    /** View Order Approval Chains */
    view_order_approval_chains?: boolean;
    /** Create Order Approval Chains */
    create_order_approval_chains?: boolean;
  };
}

/** RetrieveSelfScopeSchema */
export interface RetrieveSelfScopeSchema {
  /** Title */
  title: string;
  settings: ScopeSettingsOutput;
  logo: ScopeLogoSchema | null;
  favicon: ScopeFaviconSchema | null;
  /** ScopePermissions */
  $permissions: {
    /** Update */
    update?: boolean;
    /** View */
    view?: boolean;
    /** Invite Users */
    invite_users?: boolean;
    /** View Roles */
    view_roles?: boolean;
    /** Create Roles */
    create_roles?: boolean;
    /** View Users */
    view_users?: boolean;
    /** View User Groups */
    view_user_groups?: boolean;
    /** Create User Groups */
    create_user_groups?: boolean;
    /** View Order Approval Chains */
    view_order_approval_chains?: boolean;
    /** Create Order Approval Chains */
    create_order_approval_chains?: boolean;
  };
}

/** RetrieveSelfUserSchema */
export interface RetrieveSelfUserSchema {
  /** Pk */
  pk: number;
  /** Username */
  username: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Title */
  title: string;
  /** Phone */
  phone: string | null;
  /** Email */
  email: string;
  settings: UserSettingsOutput;
  scope: RetrieveSelfScopeSchema;
  /** Is Confirmed */
  is_confirmed: boolean;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  role: Role;
  avatar: UserAvatarSchema | null;
  /** SelfUserPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Update */
    update?: boolean;
  };
}

/** RetrieveUserGroupSchema */
export interface RetrieveUserGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Total Users */
  total_users: number;
  /** Users */
  users: RetrieveGroupUserSchema[] | null;
  /** UserGroupPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Update */
    update?: boolean;
    /** Delete */
    delete?: boolean;
  };
}

/** RetrieveUserSchema */
export interface RetrieveUserSchema {
  /** Pk */
  pk: number;
  /** Username */
  username: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Title */
  title: string;
  /** Phone */
  phone: string | null;
  /** Email */
  email: string;
  settings: UserSettingsOutput;
  /** Is Active */
  is_active: boolean;
  /** Is Confirmed */
  is_confirmed: boolean;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  role: UserRoleSchema | null;
  avatar: UserAvatarSchema | null;
  /** UserPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Update */
    update?: boolean;
  };
}

/** Role */
export interface Role {
  /** Pk */
  pk?: number;
  /** Code */
  code: string;
  /** Name */
  name: string;
  /** Scope Pk */
  scope_pk: number;
  /**
   * System
   * @default false
   */
  system?: boolean;
  /** Permissions */
  permissions: PermissionOutput[];
  /**
   * Updated At
   * @format date-time
   */
  updated_at?: string;
  /**  Permissions */
  _permissions: Record<string, boolean>;
}

/** RoleConfig */
export interface RoleConfig {
  /** Scopes */
  scopes: ScopeConfig[];
}

/** RoleNameNotUniqueError */
export interface RoleNameNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'role_name_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** ScopeCompany */
export interface ScopeCompany {
  /**
   * Industry
   * @default ""
   */
  industry?: string;
  /**
   * Employees Count
   * @default ""
   */
  employees_count?: string;
  /**
   * Owner Title
   * @default ""
   */
  owner_title?: string;
}

/** ScopeConfig */
export interface ScopeConfig {
  scope: PermissionScope;
  /** Actions */
  actions: ActionConfig[];
}

/** ScopeFaviconSchema */
export interface ScopeFaviconSchema {
  /** Uid */
  uid: string;
  variants: ScopeFaviconVariantSchema;
}

/** ScopeFaviconVariantSchema */
export interface ScopeFaviconVariantSchema {
  /** size: 32x32 */
  small?: ImageVariant;
  /** size: 96x96 */
  medium?: ImageVariant;
  /** size: 192x192 */
  big?: ImageVariant;
}

/** ScopeLogoSchema */
export interface ScopeLogoSchema {
  /** Uid */
  uid: string;
  variants: ScopeLogoVariantSchema;
}

/** ScopeLogoVariantSchema */
export interface ScopeLogoVariantSchema {
  /** size: fit-in/209x35 */
  small?: ImageVariant;
  /** size: fit-in/85x85 */
  medium?: ImageVariant;
}

/** ScopeSettings */
export interface ScopeSettingsInput {
  /**
   * Timezone
   * Отклонение от UTC в минутах.
   * @default 0
   */
  timezone?: number;
  /**
   * Currency
   * Трехбуквенный код валюты по ISO 4217.
   * @default "USD"
   */
  currency?: string;
  /**
   * Country Code
   * Код страны по ISO 3166
   */
  country_code?: string | null;
  /**
   * Mass Unit
   * Единица измерения массы.
   * @default ""
   */
  mass_unit?: string;
  /**
   * Dimension Unit
   * Единица измерения размера.
   * @default ""
   */
  dimension_unit?: string;
  /**
   * Primary Color
   * Значение главного цвета.
   * @default ""
   */
  primary_color?: string;
  /**
   * Theme
   * Название темы оформления.
   * @default ""
   */
  theme?: string;
}

/** ScopeSettings */
export interface ScopeSettingsOutput {
  /**
   * Timezone
   * Отклонение от UTC в мину��ах.
   * @default 0
   */
  timezone?: number;
  /**
   * Currency
   * Трехбуквенный код валюты по ISO 4217.
   * @default "USD"
   */
  currency?: string;
  /**
   * Country Code
   * Код страны по ISO 3166
   */
  country_code?: string | null;
  /**
   * Mass Unit
   * Единица измерения массы.
   * @default ""
   */
  mass_unit?: string;
  /**
   * Dimension Unit
   * Единица измерения размера.
   * @default ""
   */
  dimension_unit?: string;
  /**
   * Primary Color
   * Значение главного цвета.
   * @default ""
   */
  primary_color?: string;
  /**
   * Theme
   * Название темы оформления.
   * @default ""
   */
  theme?: string;
  /** Провайдер адресов */
  address_provider: AddressProvider;
  /**
   * Address Language Code
   * Язык для провайдера адресов
   */
  address_language_code: string;
}

/** SelfUserSettings */
export interface SelfUserSettings {
  /** @default "EN" */
  lang?: LangsEnum;
}

/** SignInResponseSchema */
export interface SignInResponseSchema {
  /** Access Token */
  access_token: string;
  /**
   * Access Token Expire
   * @format date-time
   */
  access_token_expire: string;
  /** Refresh Token */
  refresh_token: string;
  /**
   * Refresh Token Expire
   * @format date-time
   */
  refresh_token_expire: string;
}

/** SignInSchema */
export interface SignInSchema {
  /**
   * Username
   * Логин пользователя (почта)
   */
  username: string;
  /**
   * Password
   * Пароль
   */
  password: string;
}

/** SimpleCatalogDTO */
export interface SimpleCatalogDTO {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** SimpleOrderDTO */
export interface SimpleOrderDTO {
  /** Uid */
  uid: string;
}

/** UpdateRoleSchema */
export interface UpdateRoleSchema {
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /** Permissions */
  permissions: PermissionInput[];
}

/** UpdateScopeSchema */
export interface UpdateScopeSchema {
  /** Title */
  title: string;
  settings: ScopeSettingsInput;
  company: ScopeCompany;
  /** Logo Uid */
  logo_uid?: string | null;
  /** Favicon Uid */
  favicon_uid?: string | null;
}

/** UpdateSelfPasswordSchema */
export interface UpdateSelfPasswordSchema {
  /** Current Password */
  current_password: string;
  /** New Password */
  new_password: string;
}

/** UpdateSelfUserSchema */
export interface UpdateSelfUserSchema {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Title */
  title: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /** Phone */
  phone: string;
  settings: SelfUserSettings;
}

/** UpdateUserGroupSchema */
export interface UpdateUserGroupSchema {
  /** Name */
  name: string;
}

/** UpdateUserSchema */
export interface UpdateUserSchema {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Title */
  title: string;
  /** Phone */
  phone: string;
  /** Email */
  email: string;
  settings: UserSettingsInput;
  /** Is Active */
  is_active: boolean;
  /** Role Pk */
  role_pk: number;
}

/** UserAvatarSchema */
export interface UserAvatarSchema {
  /** Uid */
  uid: string;
  variants: UserAvatarVariantsSchema;
}

/** UserAvatarVariantsSchema */
export interface UserAvatarVariantsSchema {
  /** size: 32x32 */
  small?: ImageVariant;
  /** size: 90x90 */
  medium?: ImageVariant;
  /** size: 320x320 */
  big?: ImageVariant;
}

/** UserEmailAlreadyConfirmed */
export interface UserEmailAlreadyConfirmed {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_email_already_confirmed';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** UserEmailNotUniqueError */
export interface UserEmailNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_email_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** UserGroupDTO */
export interface UserGroupDTO {
  /** Uid */
  uid: string;
}

/** UserGroupInUseError */
export interface UserGroupInUseError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_group_in_use';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** UserGroupNameNotUniqueError */
export interface UserGroupNameNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_group_name_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** UserGroupNotUniqueInOrderApprovalChain */
export interface UserGroupNotUniqueInOrderApprovalChain {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_group_not_unique_in_order_approval_chain';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** UserGroupSchema */
export interface UserGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Total Users */
  total_users: number;
  /** Users */
  users: RetrieveGroupUserSchema[];
}

/** UserNotificationSettings */
export interface UserNotificationSettings {
  /** @default {"email":true} */
  orders_change_status?: NotificationChannels;
  /** @default {"email":true} */
  orders_for_approval?: NotificationChannels;
}

/** UserPhoneNotUniqueError */
export interface UserPhoneNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_phone_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** UserRoleSchema */
export interface UserRoleSchema {
  /** Pk */
  pk: number;
  /** Code */
  code: string;
  /** Name */
  name: string;
}

/** UserSettings */
export interface UserSettingsInput {
  /** @default "EN" */
  lang?: LangsEnum;
  /**
   * Timezone
   * Отклонение от UTC в минутах. Значение этого параметра устанавливается через аналогичное значение для scope.
   * @default 0
   */
  timezone?: number;
  /** @default {"orders_change_status":{"email":true},"orders_for_approval":{"email":true}} */
  notifications?: UserNotificationSettings;
}

/** UserSettings */
export interface UserSettingsOutput {
  /** @default "EN" */
  lang?: LangsEnum;
  /**
   * Timezone
   * Отклонение от UTC в минутах. Значение этого параметра устанавливается через аналогичное значение для scope.
   * @default 0
   */
  timezone?: number;
  /** @default {"orders_change_status":{"email":true},"orders_for_approval":{"email":true}} */
  notifications?: UserNotificationSettings;
}

/** UsernameNotUniqueError */
export interface UsernameNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'user_username_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** _Source */
export interface Source {
  /** Pointer */
  pointer?: string | null;
  /** Header */
  header?: string | null;
  /** Parameter */
  parameter?: string | null;
}

/** DeletedUserGroupInOrderApprovalChainError */
export interface DeletedUserGroupInOrderApprovalChainError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'deleted_user_group_in_order_approval_chain';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}
