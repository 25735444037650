import React from 'react';

import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { FieldType } from '@app/models/interfaces';
import {
  CatalogsSelect,
  ContactsSelect,
  GoogleAutocomplete,
  RecipientsSelect,
  Upload,
  UsersSelect,
} from '@components/AntFields';

import { MultiImage } from './components';

interface IProps {
  type: FieldType;
  form: FormInstance;
  label: string;
  name: string | string[];
  disabled?: boolean;
  required?: boolean;
  preview?: boolean;
}

export const RenderFieldByType: React.FC<IProps> = ({
  type,
  label,
  disabled = true,
  required = false,
  preview = false,
  name,
  form,
}) => {
  const validationT = useTranslation(NSKeys.validation);

  const commonRules = [
    ...(required
      ? [
          {
            required: true,
            message: validationT.t('required'),
          },
        ]
      : []),
  ];

  const getElement = () => {
    if (type === 'string' || type === 'num') {
      return (
        <Form.Item label={label} required={required} name={name} rules={commonRules}>
          <Input placeholder={label} disabled={disabled} />
        </Form.Item>
      );
    }
    if (type === 'address') {
      return (
        <GoogleAutocomplete
          name={name}
          form={form}
          rules={commonRules}
          wrapperCol={{ span: 18 }}
          label={label}
          hasError={false}
          disabled={disabled}
        />
      );
    }
    if (type === 'multi_image') {
      return (
        <Form.Item<FieldType> label={label} rules={commonRules} required={required} name={name}>
          <MultiImage disabled={preview} />
        </Form.Item>
      );
    }

    if (type === 'bool') {
      return (
        <Form.Item<FieldType>
          label={label}
          valuePropName="checked"
          name={name}
          required={required}
          rules={[
            {
              validator: (_, value) => {
                return required
                  ? value
                    ? Promise.resolve()
                    : Promise.reject(new Error(validationT.t('required')))
                  : Promise.resolve();
              },
            },
          ]}>
          <Checkbox />
        </Form.Item>
      );
    }

    if (type === 'text') {
      return (
        <Form.Item label={label} required={required} name={name} rules={commonRules}>
          <Input.TextArea placeholder={label} disabled={disabled} maxLength={280} showCount />
        </Form.Item>
      );
    }

    if (type === 'date') {
      return (
        <Form.Item
          label={label}
          required={required}
          name={name}
          rules={commonRules}
          getValueFromEvent={(e: any) => e?.format('YYYY-MM-DD')}
          getValueProps={(value) => ({ value: value ? dayjs(value) : '' })}>
          <DatePicker style={{ width: '100%' }} disabled={disabled} format="YYYY-MM-DD" />
        </Form.Item>
      );
    }

    if (type === 'datetime') {
      return (
        <Form.Item
          label={label}
          required={required}
          name={name}
          rules={commonRules}
          getValueProps={(value) => ({ value: value ? dayjs(value) : '' })}>
          <DatePicker showTime style={{ width: '100%' }} disabled={disabled} />
        </Form.Item>
      );
    }

    if (type === 'user') {
      return (
        <>
          <Form.Item label={label} required={required} name={[...name, 'id']} rules={commonRules}>
            <UsersSelect
              value={null}
              disabled={disabled}
              placeholder="test"
              onChange={(e, opt: any) => {
                form.setFieldValue([...name, 'username'], opt?.data?.username);
                form.setFieldValue([...name, 'name'], opt?.data?.first_name);
              }}
            />
          </Form.Item>

          <Form.Item
            style={{ display: 'none' }}
            label={label}
            required={required}
            name={[...name, 'username']}
            rules={commonRules}>
            <Input />
          </Form.Item>

          <Form.Item
            style={{ display: 'none' }}
            label={label}
            required={required}
            name={[...name, 'name']}
            rules={commonRules}>
            <Input />
          </Form.Item>
        </>
      );
    }

    if (type === 'recipient') {
      return (
        <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
          <RecipientsSelect
            placeholder={label}
            showSearch
            disabled={disabled}
            creatable
            onAddRecipient={(data) => {
              form.setFieldValue([...name, 'uid'], data.uid);
              form.validateFields([[...name, 'uid']]);
            }}
          />
        </Form.Item>
      );
    }

    if (type === 'contact') {
      return (
        <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
          <ContactsSelect
            placeholder={label}
            showSearch
            disabled={disabled}
            creatable
            onAddContact={(data) => {
              form.setFieldValue([...name, 'uid'], data.uid);
              form.validateFields([[...name, 'uid']]);
            }}
          />
        </Form.Item>
      );
    }

    if (type === 'catalog') {
      return (
        <>
          <Form.Item label={label} required={required} name={[...name, 'uid']} rules={commonRules}>
            <CatalogsSelect
              placeholder={label}
              disabled={disabled}
              onChange={(e, opt: any) => form.setFieldValue([...name, 'name'], opt?.label || '')}
            />
          </Form.Item>

          <Form.Item
            style={{ display: 'none' }}
            label={label}
            required={required}
            name={[...name, 'name']}
            rules={commonRules}>
            <Input />
          </Form.Item>
        </>
      );
    }

    return <div></div>;
  };

  return getElement();
};
