import React from 'react';

import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useDeleteField } from '../../../../../../../../../../../../hooks';

interface IDeleteGroupProps {
  field_uid: string;
}

export const DeleteField: React.FC<IDeleteGroupProps> = ({ field_uid }) => {
  const commonT = useTranslation(NSKeys.common);
  const ordersT = useTranslation(NSKeys.orders);

  const { mutate, status } = useDeleteField();

  return (
    <Popconfirm
      title={ordersT.t('deleteOrderFieldDescription')}
      onConfirm={(e) => {
        e?.stopPropagation();
        mutate({ field_uid });
      }}
      okText={commonT.t('confirm')}>
      <Button
        loading={status === 'loading'}
        type={'text'}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {commonT.t('buttonDelete')}
      </Button>
    </Popconfirm>
  );
};
