import { message } from 'antd';
import { AxiosError } from 'axios';
import { FieldData } from 'rc-field-form/es/interface';

import { Namespace, TFunction } from 'react-i18next';

import { IErrorResponse } from '@app/models/error';

interface IError {
  code: string;
  codeMessage: string;
  fields: string[];
}

const pathToJsonPointer = (path: string) => path.replace('.', '/').replace(/^/, '/');

export const getFormErrorsFromResponse = (
  formValues: any,
  err: any,
  t: TFunction<Namespace<'validation'>>
): { pointerErrors: FieldData[]; formMessages: string[] } => {
  const errors = err as AxiosError<IErrorResponse>;
  const formMessages: string[] = [];
  let pointerErrors: FieldData[] = [];
  const formKeys = Object.keys(formValues);
  if (errors.response?.data?.errors) {
    pointerErrors = errors.response?.data?.errors.reduce((acc, err) => {
      const formKey = formKeys.find((el) => pathToJsonPointer(el) === err?.source?.pointer);
      // console.log(err?.source?.pointer, formKey);
      if (formKey) {
        acc.push({ name: formKey, errors: [t(err.code)] });
      } else {
        formMessages.push(t(err.code));
      }
      return acc;
    }, [] as FieldData[]);
  }

  return { pointerErrors, formMessages };
};

export const getFormErrorsForAlert = (err: any, t: TFunction<Namespace<'validation'>>): IError[] => {
  const errors = err as AxiosError<IErrorResponse>;

  if (errors?.response?.data?.errors) {
    return Object.values(
      errors.response?.data?.errors.reduce((acc, item) => {
        const code = item.code;
        if (!acc[code]) {
          acc[code] = {
            code: code,
            codeMessage: item.title,
            fields: [],
          };
        }

        acc[code].fields.push(item.meta.field_name);
        return acc;
      }, {} as any)
    );
  }

  return [];
};
